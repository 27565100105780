import React from 'react'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import HelmetContent from '../components/HelmetContent'

import msc from '../assets/images/msc.jpg'
import rejuvenation from '../assets/images/full-body-rejuvenation-small.jpg'

const Landing = () => (
  <Layout>
    <HelmetContent title='AMPC Treatments' />
    <BannerLanding heading='Stem Cell Rejuvenation' />

    <div id="main">
      <section id="one" className="spotlights" style={{ backgroundColor: '#004d4d' }}>
        <section>
          <div to="/generic" className="image">
            <img src={rejuvenation} alt="" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Full Body rejuvenation</h3>
              </header>
              <p>Most of our treatments are done intravenously for full body
                  rejuvenation, and this allows for the stem cells to travel
                  to most parts of the body via the bloodstream. The bloodstream
                  acts as a highway that provides access to most parts of the body.
                  As the cells patrol the bloodstream, they can swiftly move towards
                  the damaged sites of the body that requires repair. This effectively
                  confers a form of full-body stem cell rejuvenation.<br />
                  Full-body rejuvenation treatments have resulted in unexpected health
                  benefits in the past. The immune system can be reinforced, and the
                  symptoms of chronic conditions were improved, making full-body rejuvenation
                  the most versatile form of stem cell treatment.</p>
            </div>
          </div>
        </section>
        <section>
          <div to="/generic" className="image">
            <img src={msc} style={{ height: '100%' }} alt="" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Mesenchymal Stem Cells (MSC) </h3>
              </header>
              <p>Mesenchymal Stem Cells is sourced from umbilical cord blood
                collected after birth. It is specifically retrieved from the
                Wharton’s jelly that contribute MSC with low immunogenicity which
                results is lower risk of transplant rejection. This type of stem
                cell also transforms into bone, muscle, skin, cartilage and many
                other types of tissues.</p>
            </div>
          </div>
        </section>
      </section>
    </div>

  </Layout>
)

export default Landing